<template>
    <a-form-model
      ref="matchEditForm"
      :model="form"
      :rules="rules"
    >
      <a-row>
        <a-col :span="17" class="pr-1">
          <a-form-model-item ref="url" label="Match link" prop="url">
            <a-input-search
              v-model="form.url"
              placeholder="URL"
              enter-button="Find"
              size="default"
              class="w-100"
              @search="handleSearchClick"
              :disabled="matchObj !== null"
            />
          </a-form-model-item>
          <div class="mt-1 d-flex w-100">
            <a-spin class="flex m-auto mt-2" size="large" v-if="parseLoading" />
            <div v-if="!parseLoading && matchData" class="w-100">
              <a :href="form.url" target="_blank">
                <a-card class="match-card-preview w-100" hoverable>
                  <div>
                    <div class="match_date">
                      <a-icon type="fire" theme="filled" class="text-primary mr-1" />
                      {{ $moment(matchData.starts_at).format('DD.MM.YYYY | HH:mm') }}
                    </div>
                    <div class="match_name">{{ matchData.match_name }}</div>
                    <div class="champ_name">{{ matchData.champ_name }}</div>
                  </div>
                </a-card>
              </a>
            </div>
          </div>
        </a-col>
        <a-col :span="7" class="pl-1">
          <a-form-model-item
            ref="weight"
            prop="weight"
            class="w-100 label-weight"
          >
            <template slot="label">
              Weight
              <span class="label-weight__help">max 50</span>
            </template>
            <a-input-number :min="1" :max="50" v-model="form.weight" class="w-100" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <hr>
      <a-row>
        <a-col class="text-left" :span="10">
          <span v-if="matchObj">
            <a-popconfirm
              title="Sure to delete?"
              @confirm="deleteMatch"
            >
              <a href="javascript:;">
                <a-button type="danger" :loading="deleteLoading">Delete</a-button>
              </a>
            </a-popconfirm>
          </span>
        </a-col>
        <a-col class="text-right" :span="14">
          <a-button @click="closeModal">
            Cancel
          </a-button>
          <a-button v-if="matchObj" :loading="submitLoading" :disabled="!matchData" type="primary" @click="onSubmit" class="ml-2">
            Save
          </a-button>
          <a-button v-else :loading="submitLoading" :disabled="!matchData" type="primary" @click="onSubmit" class="ml-2">
            Add
          </a-button>
        </a-col>
      </a-row>
    </a-form-model>
</template>
<script>
import TopMatchesService from '@/services/api/apiTopMatchesService'

export default {
  name: 'matchModal',
  props: ['matchObj', 'edit', 'isViewscreenSection'],
  components: {},
  data() {
    return {
      submitLoading: false,
      parseLoading: false,
      deleteLoading: false,
      matchData: this.matchObj ? this.matchObj : null,
      form: {
        url: this.matchObj ? this.matchObj.link : '',
        weight: this.matchObj ? this.matchObj.weight : 1,
      },
      rules: {
        url: [
          { required: !this.edit, message: 'Match link is required', trigger: 'change' },
        ],
        weight: [
          { required: true, message: 'Weight is required', trigger: 'change' },
        ],
      },
    }
  },
  mounted () {},
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitCreateModal(createdRecord) {
      this.$emit('createMatch', createdRecord)
    },
    submitEditModal(updatedRecord) {
      this.$emit('updateMatch', updatedRecord)
    },
    submitDeleteModal(deletedId) {
      this.$emit('deleteMatch', deletedId)
    },
    deleteSectionMatch(deletedId) {
      this.$emit('deleteSectionMatch', deletedId)
    },
    onSubmit() {
      this.$refs.matchEditForm.validate(valid => {
        if (!valid) {
          console.log('Error: Form validation failed!')
          return false
        }
        if (this.matchObj) {
          this.isViewscreenSection ? this.$emit('updateTopSort', this.form.weight, this.matchObj.id) : this.updateMatch()
        } else {
          this.createMatch()
        }
      })
    },
    handleSearchClick() {
      this.$refs.matchEditForm.validate(valid => {
        if (valid) {
          this.parseMatchUrl(this.form.url)
        } else {
          console.log('error search!!')
          return false
        }
      })
    },
    async parseMatchUrl(url) {
      this.parseLoading = true
      return TopMatchesService.parseMatchUrl(url).then((response) => {
        this.matchData = response.data.data
        this.parseLoading = false
      }).catch(error => {
        this.$notification.error({
          message: 'Error parsing url',
          description: error.message,
        })
        this.parseLoading = false
      })
    },
    async createMatch() {
      this.submitLoading = true
      return TopMatchesService.createMatch(this.form).then((response) => {
        this.submitLoading = false
        const created = response.data.data
        this.$notification.success({
          message: 'New match created',
          description: created.match_name,
          duration: 0.7,
        })
        this.submitCreateModal(created)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while creating match',
          description: error.message,
        })
        this.submitLoading = false
      })
    },
    async updateMatch() {
      this.submitLoading = true
      return TopMatchesService.updateMatch(this.matchObj.id, this.form).then((response) => {
        this.submitLoading = false
        const updated = response.data.data
        this.$notification.success({
          message: 'Match updated',
          description: updated.match_name,
        })
        this.submitEditModal(updated)
      }).catch(error => {
        console.log(error)
        this.submitLoading = false
        this.$notification.error({
          message: 'Error while updating match',
          description: error.message,
        })
      })
    },
    async deleteMatch() {
      if (this.isViewscreenSection) {
        return this.deleteSectionMatch(this.matchObj.id)
      }
      this.deleteLoading = true
      return TopMatchesService.deleteMatch(this.matchObj.id).then((response) => {
        this.$notification.success({
          message: 'Match successfully deleted',
          description: '',
        })
        this.deleteLoading = false
        this.submitDeleteModal(this.matchObj.id)
      }).catch(error => {
        console.log(error)
        this.deleteLoading = false
        this.$notification.error({
          message: 'Error while deleting match',
          description: error.message,
        })
      })
    },
  },
}
</script>

<style lang="scss">
.match-card-preview, .match-card {
  .match_date, .champ_name, .match_weight {
    font-weight: lighter;
    font-size: 12px;
  }
  .match_date { font-weight: 300; font-size: 14px; }
  .champ_name {
    margin-top: 0.5rem;
    color: #8C8C8C;
  }
  .match_name {
    font-weight: 600;
    font-size: 12px;
    margin-top: 0.8rem;
  }
  .card_badge {
    position: absolute;
    right: 0;
    top: 0;
    text-transform: none;
    border-radius: 0;
  }
  .card_info {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0px 4px 2px 0;
    color: #ccc;
    transition: 0.3s all ease-out;
    &:hover {
      color: #333;
    }
  }
  .match_odds {
    margin-top: 1rem;
    //border-radius: 8px;
    .match_odd {
      padding: 2px;
      .odd_header, .odd_val {
        text-align: center;
        font-size: 12px;
      }
      .odd_val {
        font-weight: bold;
      }
    }
  }
}
.label-weight {
  & .ant-form-item-label {
    overflow: visible;
  }
  &__help {
    position: absolute;
    top: 50%;
    right: -35px;
    transform: translateY(-50%);
    color: #8c8c8c;
    font-size: 12px;
    font-weight: 400;
  }
}
</style>
