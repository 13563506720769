<template>
  <div class="p-4">
    <div class="mb-3 d-flex">
      <span class="mt-0">
        <img v-if="matchGroup.matches[0].sport_id" width="18" height="18"
          :src="`https://api.olimp.work/storage/sports/${matchGroup.matches[0].sport_id}.png`" />
      </span>
      <span class="ml-2 text-gray-8 top-matches__champ-name" style="margin-top: 0.1rem;">{{ matchGroup.sportName +
        '. ' + matchGroup.champName }}</span>
    </div>
    <a-table :columns="columns" :data-source="filteredMatches" :rowKey="(record, index) => index" :loading="loading"
      class="top-matches__table mb-4 operations-table" :pagination="false"
      :rowClassName="(record, index) => record.created_at === record.updated_at ? '' : 'highlight-row'">>
      <template slot="createdAt" slot-scope="text, record">
        <span class="mr-2">{{ $moment(record.created_at).format('DD.MM.YY') }}</span>
        <span>{{ $moment(record.created_at).format('HH:mm') }}</span>
      </template>
      <template slot="actions" slot-scope="text, record">
        <div class="row-operations">
          <span :class="{ 'text-gray-5 icon-disabled': !isEditAvailable }">
            <a class="font-size-18 edit-icon text-primary" :class="{ 'text-gray-5 icon-disabled': !isEditAvailable }" :disabled="!isEditAvailable" @click="editTopMatch(record.id)">
              <a-icon type="edit" /></a>
            <a-popconfirm :disabled="!isEditAvailable" placement="left" title="Sure to delete this match?"
              @confirm="() => deleteTopMatch(record.id)">
              <a-icon class="font-size-18 text-danger delete-icon" :class="{ 'text-gray-5 icon-disabled': !isEditAvailable }"  type="delete" />
            </a-popconfirm>
          </span>
        </div>
      </template>
    </a-table>
    <div class="pagination-container">
      <div class="d-flex align-items-center">
        <span class="mr-2">Max: </span>
        <a-tooltip slot="suffix" title="Max visible to user lines" class="mr-3">
          <a-icon type="question-circle" style="color: rgba(0,0,0,.45)" />
        </a-tooltip>
        <a-input-search v-model="visibleMatchRows" :disabled="!toggleEditRows" class="limit-input">
          <a-button v-if="!toggleEditRows" slot="enterButton" :disabled="!groupeChampId || !isEditAvailable">
            <a class="font-size-12 edit-icon" @click="editVisibleRows" style="color: rgba(0,0,0,.45)">
              <a-icon type="edit" />
            </a>
          </a-button>
          <a-button v-else slot="enterButton">
            <a class="font-size-12 check-icon" @click="updateChampLimits" style="color: rgba(0,0,0,.45)">
              <a-icon type="check" />
            </a>
          </a-button>
        </a-input-search>
      </div>
      <a-pagination v-model="pagination.current" :pageSize="pagination.pageSize"
        :total="totalPagination" />
      <a-button :disabled="isShowAllDisabled" @click="resizeTable" type="link">
        {{showAllText}}
      </a-button>
    </div>
  </div>
</template>
<script>
import ViewscreenService from '@/services/api/apiViewscreenService'
export default {
  name: 'topMatchesTable',
  props: ['matchGroupData', 'limits', 'sectionId', 'isEditAvailable'],
  components: {
  },
  data: () => ({
    loading: false,
    pagination: {
      pageSize: 6,
      current: 1,
    },
    visibleMatchRows: 10,
    showAll: false,
    matchGroup: null,
    matchGroupLimits: null,
    toggleEditRows: false,
    columns: [
      {
        title: 'Match name',
        dataIndex: 'match_name',
        width: '20%',
      },
      {
        title: 'Created',
        dataIndex: 'created_at',
        width: '20%',
        scopedSlots: { customRender: 'createdAt' },
      },
      {
        title: 'Match ID',
        dataIndex: 'match_id',
        width: '20%',
      },
      {
        title: 'Weight',
        dataIndex: 'weight',
        width: '20%',
      },
      {
        title: 'Actions',
        width: '7%',
        scopedSlots: { customRender: 'actions' },
      },
    ],
  }),
  watch: {
    isActive(value) {
      this.isActiveSectionStatus = value
    },
    matchGroupData(newValue) {
      this.matchGroup = newValue
    },
    // visibleMatchRows: debounce(function (newValue) {
    //   if (!this.initialRender) {
    //     this.updateLimit(newValue)
    //     this.visibleMatchRows === 'Все' || this.visibleMatchRows === 'Всё' ? this.pagination.pageSize = 9999 : this.pagination.pageSize = this.visibleMatchRows
    //   }
    // }, 1000),
    visibleMatchRows(newValue) {
      const stringValue = String(newValue)
      this.visibleMatchRows = stringValue.replace(/\D/g, '')
      if (this.visibleMatchRows > 10) {
        this.visibleMatchRows = 10
        this.$notification.warning({
          message: 'Visible rows number cannnot be more than 10',
        })
      }
      this.showAll = false
    },
  },
  computed: {
    filteredMatches() {
      if (this.showAll) {
        return this.matchGroup.matches
      }
      const start = (this.pagination.current - 1) * this.pagination.pageSize
      const end = start + this.pagination.pageSize

      return this.matchGroup.matches.slice(start, end)
    },
    groupeChampId() {
      if (this.matchGroup && Array.isArray(this.matchGroup.matches)) {
        const match = this.matchGroup.matches.find(match => match.champ_id || match.rid)
        if (match) {
          return match.champ_id ? match.champ_id : match.rid
        }
      }
      return null
    },
    isShowAllDisabled() {
      if (this.showAll) {
        return false
      }
      return this.matchGroup.matches.length <= this.pagination.pageSize
    },
    showAllText() {
      return this.showAll ? 'Show less' : 'Show all'
    },
    totalPagination() {
      return this.matchGroup.matches.length
    },
  },
  created() {
    this.matchGroup = this.matchGroupData

    if (this.limits.length) {
      const matchedGroupe = this.limits.find(groupe => this.groupeChampId === groupe.champ_id)
      this.matchGroupLimits = matchedGroupe ? matchedGroupe.limit : -1
    } else {
      this.matchGroupLimits = -1
    }

    if (this.matchGroupLimits !== -1) {
      this.visibleMatchRows = this.matchGroupLimits
    }
    this.pagination.pageSize = this.visibleMatchRows
  },
  methods: {
    async updateLimit() {
      if (this.visibleMatchRows <= 0) {
        this.visibleMatchRows = 1
        this.$notification.warning({
          message: 'Visible rows number cannnot be less than 1',
        })
      }
      try {
        const data = {
          champ_id: this.groupeChampId,
          limit: this.visibleMatchRows,
        }
        const response = await ViewscreenService.updateMatchGroupLimit(this.sectionId, data)
        if (response.data.data) {
          this.$notification.success({
            message: 'Visible rows have been successfully updated',
          })
          this.pagination.pageSize = Number(this.visibleMatchRows)
          this.showAll = false
          this.$emit('toggleIsPublished')
        }
      } catch (error) {
        this.$notification.error({
          message: 'Error while updating visible rows',
          description: error.message,
        })
      }
    },
    resizeTable() {
      this.showAll = !this.showAll
      this.showAll ? this.pagination.pageSize = 9999 : this.pagination.pageSize = Number(this.visibleMatchRows)
    },
    editTopMatch(matchId) {
      this.$emit('editTopMatch', matchId)
    },
    deleteTopMatch(matchId) {
      this.$emit('deleteTopMatch', matchId)
    },
    updateChampLimits() {
      this.updateLimit(this.visibleMatchRows)
      this.pagination.pageSize = Number(this.visibleMatchRows)
      this.toggleEditRows = false
    },
    editVisibleRows() {
      this.toggleEditRows = true
      this.pagination.pageSize = Number(this.visibleMatchRows)
    },

  },
}
</script>
<style lang="scss" scoped>
</style>
