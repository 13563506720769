<template>
  <div class="informers-section">
    <a-card>
      <template #title>
        <div class="mt-1">
          <div class="d-flex font-size-20">
            <span v-if="sectionTitle" class="section-title" :class="{ 'hidden-title': !showLabel }">
              {{ sectionTitle }}
            </span>
          </div>
        </div>
      </template>
      <template #extra>
        <div class="d-flex align-items-center section-extra font-size-20 text-right">
            <a-button :disabled="!isEditAvailable" type="primary" icon="plus" @click="createInformer">
              Add
            </a-button>
            <a-button :disabled="!isEditAvailable" type="primary" icon="plus" @click="openInformersPage">
              Select existing
            </a-button>
          <div v-if="isEditAvailable" style="margin-top: 0.1rem">
            <a-tooltip title="Edit section" placement="bottom">
              <a-icon @click="editSection" type="edit" class="main-tabs__sections__icons text-primary" />
            </a-tooltip>
          </div>
          <div>
            <a-tooltip v-if="index < maxIndex - 1 && isEditAvailable" title="Move down" placement="bottom">
              <a-icon @click="moveDown" type="down" class="movable move-down text-primary" />
            </a-tooltip>
            <a-icon v-else type="down" class="movable text-gray-5" />
          </div>
          <div>
            <a-tooltip v-if="index > 0 && isEditAvailable" title="Move up" placement="bottom">
              <a-icon @click="moveUp" type="up" class="movable move-up text-primary" />
            </a-tooltip>
            <a-icon v-else type="up" class="movable text-gray-5" />
          </div>
          <a href="javascript:" class="text-primary" @click.stop="changeStatusSection">
            <a-switch v-model="isActiveSectionStatus" :disabled="!isEditAvailable" />
          </a>
          <div v-if="isEditAvailable" @click="toggleCarousel" class="dropDown-icon"
            :class="{ 'spin': showAsList }">
            <DropDownIcon />
          </div>
        </div>
      </template>
      <template #cover>
        <transition-group tag="div" class="carousel-container" type="transition" name="flip-grid">
          <a-carousel v-if="!showAsList" arrows :slidesToShow="computedSlidesToShow" :slidesToScroll="1" :dots="false" variableWidth :infinite="false" :key="1">
            <div style="z-index: 1" slot="prevArrow"><a-icon type="left" class="text-primary"></a-icon></div>
            <div style="z-index: 1" slot="nextArrow"><a-icon type="right" class="text-primary"></a-icon></div>
            <div v-for="(informer) in informers" :key="informer.id" class="promo-container" style="width: 309px; ">
              <div class="promo-item mr-3 informer" :class="{'disabled': !informer.is_active}">
                <div class="img-container">
                  <div class="d-flex align-items-center">
                    <component class="mr-2" v-if="tags.find(tag => tag.name === informer.tag)?.icon" :is="tags.find(tag => tag.name === informer.tag).icon" />
                    <a-tooltip class="mr-3" :title="informer.localizations[selectedLang].title" placement="top">
                      <span>{{ informer.localizations[selectedLang].title.length > 23 ? informer.localizations[selectedLang].title.slice(0, 23) + '...' : informer.localizations[selectedLang].title }}</span>
                    </a-tooltip>
                    <a-icon class="informer__arrow" type="right"></a-icon>
                  </div>
                  <div class="overlay">
                    <div class="edit-informer pointer" @click="editInformer(informer.id)">
                      <a-icon type="edit" class="text-white" />
                    </div>
                    <a-popconfirm placement="right" title="Do you really want to delete it?"
                      class="delete-banner pointer" @confirm="deleteInformer(informer.id)">
                      <a-icon type="delete" class="text-white" />
                    </a-popconfirm>
                  </div>
                </div>
              </div>
            </div>
          </a-carousel>
          <draggable v-else v-model="informers" v-bind="dragOptions" @start="drag = true" @end="sortInformers" tag="div"
            :key="2">
            <transition-group>
              <div v-for="(informer) in informers" :key="informer.id" class="d-inline-block" style="margin-bottom: 1.5rem;">
              <div class="promo-item mr-3 informer" :class="{'disabled': !informer.is_active}">
                <div class="img-container">
                  <div class="d-flex align-items-center">
                    <component class="mr-2" v-if="tags.find(tag => tag.name === informer.tag)?.icon" :is="tags.find(tag => tag.name === informer.tag).icon" />
                    <a-tooltip class="mr-3" :title="informer.localizations[selectedLang].title" placement="top">
                      <span>{{ informer.localizations[selectedLang].title.length > 23 ? informer.localizations[selectedLang].title.slice(0, 23) + '...' : informer.localizations[selectedLang].title }}</span>
                    </a-tooltip>
                    <a-icon class="informer__arrow" type="right"></a-icon>
                  </div>
                  <div class="overlay">
                    <div class="edit-informer pointer" @click="editInformer(informer.id)">
                      <a-icon type="edit" class="text-white" />
                    </div>
                    <a-popconfirm placement="right" title="Do you really want to delete it?"
                      class="delete-banner pointer" @confirm="deleteInformer(informer.id)" :getPopupContainer="getPopupContainer">
                      <a-icon type="delete" class="text-white" />
                    </a-popconfirm>
                  </div>
                </div>
              </div>
            </div>
            </transition-group>
          </draggable>
        </transition-group>
      </template>
    </a-card>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import DropDownIcon from '@/components/custom/icons/dropDownIcon.vue'
import NewIcon from '@/components/custom/icons/informers/newIcon.vue'
import PromoIcon from '@/components/custom/icons/informers/promoIcon.vue'
import TopIcon from '@/components/custom/icons/informers/topIcon.vue'
import TechIcon from '@/components/custom/icons/informers/techIcon.vue'
import MaintenanceIcon from '@/components/custom/icons/informers/maintenanceIcon.vue'

import ViewscreenService from '@/services/api/apiViewscreenService'

export default {
  name: 'informersSection',
  props: ['informersData', 'index', 'maxIndex', 'selectedLang', 'langs', 'showLabel', 'isActive', 'localizations', 'sectionId', 'activeTabId', 'isEditAvailable'],
  components: {
    draggable,
    DropDownIcon,
    NewIcon,
    PromoIcon,
    TopIcon,
    TechIcon,
    MaintenanceIcon,
  },
  data: () => ({
    isActiveSectionStatus: false,
    informers: null,
    drag: false,
    showAsList: false,
    windowWidth: window.innerWidth,
    tags: [
      { name: 'new', icon: 'NewIcon' },
      { name: 'promo', icon: 'PromoIcon' },
      { name: 'top', icon: 'TopIcon' },
      { name: 'tech', icon: 'TechIcon' },
      { name: 'maintenance', icon: 'MaintenanceIcon' },
      { name: 'no tag' },
    ],
  }),
  watch: {
    isActive(value) {
      this.isActiveSectionStatus = value
    },
    informersData(value) {
      this.informers = value
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    selectedLangId() {
      return this.langs.find(lang => this.selectedLang === lang.shortcut).value
    },
    sectionTitle() {
      const localization = this.localizations.find(section => Number(section.lang_id) === Number(this.selectedLangId))
      return localization ? localization.value : null
    },
    computedSlidesToShow() {
      switch (true) {
        case this.windowWidth >= 2400:
          return 7
        case this.windowWidth >= 1920:
          return 5
        case this.windowWidth >= 1200:
          return 5
        case this.windowWidth <= 1200:
          return 4
        default:
          return 6
      }
    },
  },
  created() {
    this.informers = this.informersData
    this.isActiveSectionStatus = this.isActive
  },
  mounted() {
    window.addEventListener('resize', this.updateWindowWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth)
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth
    },
    editInformer(informerId) {
      this.$router.push(`/informers/edit-informer/${informerId}/${this.activeTabId}/${this.sectionId}`)
    },
    createInformer() {
      this.$router.push(`/informers/create-informer/${this.activeTabId}/${this.sectionId}`)
    },
    openInformersPage() {
      this.$router.push(`/informers/informers-list/${this.activeTabId}/${this.sectionId}`)
    },
    toggleCarousel() {
      this.showAsList = !this.showAsList
    },
    editSection() {
      this.$emit('editSection', this.sectionId)
    },
    moveDown() {
      this.$emit('moveDownSection', this.sectionId, 1)
    },
    moveUp() {
      this.$emit('moveUpSection', this.sectionId, -1)
    },
    changeStatusSection() {
      this.$emit('changeStatusSection', this.sectionId, this.isActiveSectionStatus)
    },
    getPopupContainer() {
      return document.body
    },
    async sortInformers() {
      const informerSort = this.informers.map((informer, index, informerArr) => {
        return {
          id: informer.id,
          sort: informerArr.length - index,
        }
      })
      const sort = { sort: informerSort }
      try {
        await ViewscreenService.sortSectionItems(this.sectionId, sort)
        this.$notification.success({
          message: 'Sucessfully sorted',
        })
        this.$emit('toggleIsPublished')
      } catch (error) {
        console.log(error)
      }
      this.drag = false
    },
    async deleteInformer(informerId) {
      this.loading = true
      try {
        const response = await ViewscreenService.deleteViewscreenSectionItem(this.sectionId, informerId)
        if (response.data.success === true) {
          const deletedInformerIndex = this.informers.findIndex(informer => informer.id === informerId)
          if (deletedInformerIndex !== -1) {
            this.informers.splice(deletedInformerIndex, 1)
          }
          this.$notification.success({
            message: 'Informer has been successfully deleted from section',
          })
          this.$emit('toggleIsPublished')
        }
      } catch (error) {
        this.$notification.error({
          message: 'Error while deleting informer',
          description: error.message,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style lang="scss" src="./style.module.scss" />
